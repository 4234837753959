:root {
  --mainDark: #343a40;
  --mainGrey: rgba(17, 14, 14, 0.616);
  --mainLightGrey: #2422227e;
  --mainWhite: #fff;
  --mainBlue: #14ebc7;
  --mainGreen: #28a745;
  --mainRed: #940c0c;
}
.wuwbtitle {
  height: 4em;
  margin-top: -1em;
  margin-left: -1em;
}

.termcondition {
  white-space: nowrap;
  text-align: center;
  font-size: 15px;
  justify-content: center;
  display: flex;
}
.text-xs-center {
  text-align: center;
}

@media only screen and (max-width: 1201px) {
  .footer-middle {
    margin: auto;
  }
}

@media only screen and (max-width: 1030px) {
  .text-xs-center {
    display: none;
  }
}

@media only screen and (max-width: 991.9px) {
  .wuwbtitle {
    height: 4em;
    margin-top: -1em;
    margin-left: -3em;
  }
}
@media only screen and (max-width: 769.9px) {
  .termcondition {
    display: none;
  }
  hr {
    display: none;
  }
}
@media only screen and (max-width: 405px) {
  a1 {
    font-size: 35px;
  }
}

@media only screen and (max-width: 840px) and (min-width: 768px) {
  .list-unstyled {
    font-size: 13px;
  }
  .list-unstyled-headers {
    font-size: 24px;
  }
}
@media only screen and (max-width: 767.9px) {
  .wuwbtitle {
    height: 5em;
    margin-top: 0px;
    margin-left: -1em;
  }
}
