:root {
  --animation-speed: 2s;
  --primary-color: #fff;
  --secondary-color: #000;
  --background-color: #313131;

  /*    Spinner Variables */
  --spinner-size: 10;
  --line-color: #d7958e;
  --line-alpha: 0.73;
  --ring-color: #000;
  --ring-alpha: 0.5;
  --ring-size: 4;
}

.spinner {
  font-size: calc(var(--spinner-size) * 1em);
  width: 1em;
  height: 10em;
  border-radius: 50%;

  position: absolute;
  top: 1em;
  bottom: 0;
  left: 0;
  right: 0;

  margin: auto;
}
.spinner .line {
  fill: none;
  stroke: var(--line-color);
  stroke-width: var(--ring-size);
  opacity: var(--line-alpha);
  stroke-linecap: round;
  transform-origin: 50% 50%;
  transform: rotate3d(0, 0, 1, 0deg);
  animation: 2156ms spinner-arc ease-in-out infinite,
    1829ms spinner-rotate linear infinite;
}
.spinner .ring {
  fill: none;
  stroke: var(--ring-color);
  stroke-width: var(--ring-size);
  opacity: var(--ring-alpha);
}
@keyframes spinner-rotate {
  to {
    transform: rotate3d(0, 0, 1, 360deg);
  }
}
@keyframes spinner-arc {
  from {
    stroke-dasharray: 0 150;
    stroke-dashoffset: 0;
  }
  to {
    stroke-dasharray: 100 150;
    stroke-dashoffset: -140;
  }
}

#showMe {
  animation: animation 9s 0s forwards;
  opacity: -1;
}

@keyframes animation {
  from {
    opacity: -1;
  }
  to {
    opacity: 1;
  }
}

@media only screen and (max-width: 769.9px) {
  #showMe {
    font-size: 23px;
  }
  .animation {
    white-space: nowrap;
  }

  .spinner {
    width: 120px;
  }
}
