.padding {
  padding-top: 6em;
  padding-bottom: 6em;
}
.aboutcard {
  width: 18rem;
  margin: 0px auto;
  border: none;
  border-radius: 28px;
  height: 19rem;
}
.aboutcard-body {
  padding: 0rem;
  padding-top: 8rem;
  height: 12rem;
}

.aboutcard-text {
  font-size: 0.9rem;
  padding: 0.4rem 1.9rem;
}
.card-title {
  text-align: center;
}
.aboutcard-text {
  text-align: center;
}

.image-top {
  height: 107px;
  width: 180px;
  position: absolute;
  z-index: 1;
  margin-top: -81px;
  border-radius: 90px;
  margin-left: 58px;
}

.image-top .card-img-tops {
  width: 100%;
  height: 177px;
  border-radius: 90px;
  border: 4px solid #1073a8;
  box-shadow: 0 0 0 7px rgb(0, 0, 0);
}

.btn-portfolio {
  color: rgb(26, 22, 22);
  background-color: #0288d1;
  border-radius: 18px;
  width: 7em;
  height: 2em;
  font-weight: bold;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 14px 0px 0px 0px;
  position: relative;

  left: 30%;
}
.btn-portfolio:focus {
  outline: 0;
}
.btn-portfolio:hover {
  background-color: #fff;
}

h3.card-title {
  font: normal normal normal 24px/25px Baskerville Old Face;
  color: #0288d1;

  opacity: 1;
}

h6.card-title {
  color: #ffffff;
  font: normal normal normal 18px/25px;
  opacity: 1;
}
.about-card-text {
  color: #ffffff;
  font: normal normal normal 15px/19px Baskerville Old Face;
  text-align: center;
}
li {
  list-style: none;
}
.socialicon {
  display: inline-flex;
  margin-top: 8px;
  margin-left: 25px;
}

.linkedInIcon {
  padding-right: 15px;
  padding-left: 0px;
  color: #0288d1;
}
.instagramicon {
  padding-right: 15px;
  color: #0288d1;
  padding-left: 10px;
}
.emailicon {
  padding-right: 15px;
  padding-left: 10px;
  color: #0288d1;
}
.whatsappicon {
  color: #0288d1;
  padding-right: 15px;
  padding-left: 10px;
}
.linkedInIcon:hover,
.whatsappicon:hover,
.emailicon:hover,
.instagramicon:hover {
  color: #ffffff;
}
