.row {
  --bs-gutter-x: 0rem !important;
}
#myNav {
  background: linear-gradient(#c9c6de, #b7b3d4) !important;
  width: 100% !important;
  padding: 10px 2em;
}

.navbar-light {
  background: #000 !important;
  font-weight: bold;
  font-family: sans-serif;
  width: 100%;
}

button:hover {
  cursor: pointer;
  border-color: transparent;
}

.search {
  margin-bottom: auto;
  margin-top: 0px;
  z-index: 3;
  background-color: #fff;
  border-radius: 20px;
  padding: 5px 0px 0px 0px;
  box-shadow: 3px 8px 0px 0px #888888;
}

.search-input {
  color: white;
  border: 0;
  outline: 0;
  background: none;
  width: 0;
  margin-top: 1px;
  caret-color: transparent;
  line-height: 8px;
  transition: width 0.4s linear;
}
.search-icon {
  color: #c9c6de;
}

.search .search-input {
  margin-left: 7px;
  width: 550px;
  caret-color: #c9c6de;
  font-size: 18px;
  font-family: ui-sans-serif;
  font-weight: 300;
  color: black;
  transition: width 0.4s linear;
}

.search:hover > .search-icon {
  background: transparent;
  color: #000;
}
.usersearch {
  width: 96%;
  margin-left: 7px;
  margin-top: 1em;
  font-family: sans-serif;
  height: 30px;
}
.searchpara {
  margin-left: 1em;
  margin-top: -1em;
}
.usersearch:hover {
  width: 100%;
  margin-left: 0em;
  background-color: #b1b1b1c4;

  cursor: pointer;
}
.btn-sm-search {
  display: inline;
  width: 35px;
  background-size: 100%;
  border: none;
  margin-left: -4px;
  outline: none;
  background-color: transparent;
}

.dropdown.nav-item {
  list-style-type: none;
}

.btn-secondary,
.btn-secondary:focus,
.btn:active:focus,
.btn:first-child:active,
.btn:focus,
.btn:hover,
.btn:active:focus,
.btn.active:focus {
  border: none !important;
  background: transparent !important;
  border-color: transparent !important;
  outline: none !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.myaccountjpg {
  width: 2em;
  height: 2em;
  margin-top: -17px;
  z-index: 2;
}

.WUWBLogo {
  height: 35px;
  margin-right: 2em;
}
a.nav-link {
  color: rgba(0, 0, 0, 0.55);
}
a.nav-link:hover {
  color: black !important;
}

@media only screen and (min-width: 768px) {
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 1.5rem !important;
  }
}
@media only screen and (max-width: 1215px) {
  .search {
    width: 350px;
  }
  .search-input {
    width: 2px;
  }

  .search .search-input {
    padding: 0px 40px 0px 10px;
    width: 100%;
  }
}

@media only screen and (max-width: 694px) {
  .navbar-expand-md {
    text-align: center;
  }
  .navbar-expand-md {
    text-align: center;
  }

  .dropdown.nav-item {
    list-style-type: none;
    display: inline-table;
  }
}
@media only screen and (max-width: 860px) and (min-width: 768px) {
  .navbar {
    width: 100vw;
  }

  .search-input {
    display: none;
  }
  .btn-sm-search {
    display: none;
  }
  .search .search-input {
    display: none;
  }
  .search {
    display: none;
  }
  .myaccountjpg {
    margin-right: -2em;
  }
  .dropdown-toggle::after {
    margin-left: 3em;
  }
  .search .search-input {
    display: none;
  }
}

.loginText {
  font-weight: bold;
  display: flex;
}
@media only screen and (max-width: 767px) {
  .loginText {
    flex-flow: column;
    align-items: center;
  }
  .searchnavbar {
    display: none !important;
  }
}
.drop-Item {
  background-color: #fff;
  border-radius: 5px;
  position: absolute;
  right: 0px;
  top: 25px;
  min-width: 10em;
  width: max-content;
  height: 4em;
  text-align: center;
  animation: slide 0.4s linear;
  padding: 5px;
  z-index: 0;
}
.drop-Item span:first-child {
  border-bottom: 1px solid rgba(84, 84, 84, 0.667);
}
.drop-Item span {
  padding: 2px 0;
  white-space: nowrap;
}

@keyframes slide {
  0% {
    top: 10px;
    opacity: 0;
  }
  100% {
    top: 25px;
    opacity: 1;
  }
}

@media only screen and (max-width: 767px) {
  #myNav {
    padding: 10px;
  }
  .navbar-expand-md {
    text-align: center;
  }
  .WUWBLogo {
    margin-top: 0px;
  }

  .search {
    display: none;
  }

  .search-input {
    display: none;
  }
  .navbar-light .navbar-toggler {
    margin-right: 1em;
  }
  .search .search-input {
    display: none;
  }
  .myaccountjpg {
    margin-top: 0px;
  }
  .drop-Item {
    right: 30%;
    top: 36px;
  }
}
