body {
  background: linear-gradient(#b7b3d4, #d7958e);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.App {
  background: url("./assests/WUWBlogin.png") center center/cover no-repeat;
  background-size: 100%;
  height: 100vh;
  width: 100%;
  display: flex;
}
.contactus {
  background: url("./assests/What U Want Be.png") center center/cover no-repeat;
  background-size: 40%;
  height: 100vh;
  width: 100%;
  display: flex;
}
.About {
  background: url("./assests/What U Want Be.png") no-repeat center;
  background-size: 40%;
  height: 100%;
  width: 100%;
  display: flex;
}
.homebg {
  background: linear-gradient(#b7b3d4, #d7958e);
  height: 100%;
  width: 100%;
}
.loadings {
  background-size: 100%;
  height: 100vh;
  width: 100%;
}
.blog {
  font-family: sans-serif;
  background: linear-gradient(#b7b3d4, #d7958e);
  height: 100%;
  width: 100%;
}
.course {
  background: url("./assests/What U Want Be.png") no-repeat center;
  background-size: 45%;
  font-family: sans-serif;
  height: 100%;
  width: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
a.active-class {
  /* border-bottom: 1px solid rgb(0, 0, 0); */
  color: #000 !important;
  font-weight: bold !important;
  font-size: 16px !important;
}

@media (max-width: 1399.9px) {
  .About {
    background-size: 30%;
  }
}

@media (max-width: 1200px) {
  .course {
    background-size: 30%;
  }
  .About {
    background-size: 20%;
  }
}

@media (max-width: 600px) {
  .course {
    background-size: 40%;
  }
  .About {
    background-size: 35%;
  }
  .contactus {
    background-size: 70%;
  }
}
