.paddingbook {
  margin-bottom: 4em;
}
.Boooksearcheddiv {
  margin-bottom: auto;
  margin-top: 0px;
  z-index: 2;
  background-color: #fff;
  border-radius: 6px;
  padding: 5px 0px 0px 0px;
}
.bookssearched {
  color: white;
  border: 0;
  outline: 0;
  margin-left: 3px;
  background: none;
  width: 0;
  margin-top: 1px;
  caret-color: transparent;
  line-height: 8px;
  transition: width 0.4s linear;
}
.Boooksearcheddiv,
.bookssearched {
  width: 19em;
  caret-color: #c9c6de;
  font-size: 18px;
  font-family: ui-sans-serif;
  font-weight: 300;
  color: black;
  transition: width 0.4s linear;
}

.usersearched {
  width: 100%;
  margin-left: 4px;
  font-family: sans-serif;
  height: 30px;
}
.usersearched:hover {
  width: 100%;
  margin-left: 0em;
  background-color: #b1b1b1c4;
  cursor: pointer;
}
.searchpara {
  margin-left: 2em;
  margin-top: -1em;
}
.reqBook-btn {
  display: flex;
  justify-content: end;
}
.reqBook-btn button {
  background-color: #1190c7;
  border-radius: 18px;
  width: 9em;
  height: 2.5em;
  color: #fff;
  margin-right: 160px;
  font-weight: bold;
  border: none;
}
.reqBook-btn button:hover {
  background-color: #055e85;
  border: none;
  box-shadow: 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
.reqBook-btn button:focus {
  outline: 0;
}

@media (max-width: 350px) {
  .Boooksearcheddiv,
  .bookssearched {
    width: 16.7em;
  }
}

@media (max-width: 1200px) {
  .reqBook-btn button {
    margin-right: 130px;
  }
}

@media (max-width: 855.9px) {
  .reqBook-btn button {
    margin-right: 30px;
    width: 7.2em;
    height: 2.2em;
    font-size: 15px;
  }
}
