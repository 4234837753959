.Most-populardiv {
  display: flex;
  justify-content: center;
  text-align: center;
}
.bannercard {
  margin-bottom: 5em;
  margin-top: 3em;
}
.btn-card:hover {
  color: #fff;
}

.carousel-indicators li {
  width: 10px;
  height: 10px;
  margin-left: 1em;
  border-radius: 100%;
}
.carousel-indicators {
  bottom: -50px;
}
div span {
  display: block;
}

/* First Content */
.homeimg {
  width: 40em;
  float: left;
}

.home-list {
  margin-left: 29em;
  margin-top: -30px;
}
.home-content {
  font-size: 20px;
  list-style: none;
  color: #000;
  height: 27px;
}
.home-content::before {
  content: " •";
  display: inline-block;
  width: 30px;
  margin-left: -1em;
  color: #001d93;
  font-size: 30px;
}
.hometitle {
  text-align: start;
  color: #001d93;
  font-weight: 600;
  font-size: 45px;
  margin-left: 10em;
}
.IIndheading {
  text-align: start;
  color: #001d93;
  font-weight: 600;
  font-size: 22px;
  margin-left: 21em;
  margin-top: -30px;
}
@media only screen and (max-width: 1255.9px) {
  .hometitle {
    font-size: 40px;
    margin-left: 7em;
  }
  .IIndheading {
    font-size: 20px;
    margin-left: 14em;
  }
  .home-list {
    margin-left: 18em;
    margin-top: -30px;
  }
  .homeimg {
    width: 35em;
    margin-top: 30px;
  }
}

@media only screen and (max-width: 935.9px) {
  .hometitle {
    font-size: 30px;
    margin-left: 8em;
  }
  .IIndheading {
    font-size: 15px;
    margin-left: 16.3em;
  }
  .home-list {
    margin-left: 15.5em;
    margin-top: -35px;
  }
  .homeimg {
    width: 25em;
    margin-top: 70px;
  }
  .home-content {
    font-size: 18px;
  }
  .home-content::before {
    width: 18px;
    font-size: 25px;
    margin-left: -1em;
  }
}
@media only screen and (max-width: 767.9px) {
  .hometitle {
    font-size: 30px;
    margin-left: 20px;
  }
  .IIndheading {
    font-size: 15px;
    margin-left: 20px;
  }
  .home-list {
    margin-left: 20px;
    margin-top: -35px;
  }
  .homeimg {
    width: 20em;
    margin-left: -30px;
  }
  .home-content {
    font-size: 18px;
  }
  .home-content::before {
    width: 18px;
    font-size: 22px;
    margin-left: -1em;
  }
}
@media only screen and (max-width: 575.9px) {
  .hometitle {
    font-size: 40px;
    margin-left: 3em;
    margin-top: -2em;
  }
  .IIndheading {
    font-size: 20px;
    margin-left: 6.5em;
  }
  .home-list {
    margin-left: 7.8em;
    margin-top: -25px;
  }
  .homeimg {
    width: 25em;
    display: block;
    margin-left: 5.6em;
    margin-right: 0;
    margin-top: -1em;
  }
  .home-content {
    font-size: 22px;
  }
  .home-content::before {
    width: 18px;
    font-size: 22px;
    margin-left: -1em;
  }
}

@media only screen and (max-width: 511.9px) {
  .hometitle {
    font-size: 40px;

    margin-top: -2em;
  }
  .IIndheading {
    font-size: 20px;
    margin-left: 6.2em;
  }
  .home-list {
    margin-left: 7.3em;
  }
  .homeimg {
    width: 25em;
    margin-left: 4em;
  }
}

@media only screen and (max-width: 467.9px) {
  .hometitle {
    font-size: 30px;
    margin-left: 4em;
    margin-top: -2em;
  }
  .IIndheading {
    font-size: 18px;
    margin-left: 6.5em;
  }
  .home-list {
    margin-left: 7em;
  }
  .homeimg {
    width: 20em;
    margin-left: 5em;
  }
  .home-content {
    font-size: 20px;
  }
}

@media only screen and (max-width: 429.9px) {
  .hometitle {
    font-size: 30px;
    margin-left: 4em;
    margin-top: -2em;
  }
  .IIndheading {
    font-size: 15px;
    margin-left: 8em;
  }
  .home-list {
    margin-left: 7em;
    margin-top: -30px;
  }
  .homeimg {
    margin-left: 4em;
  }
  .home-content {
    font-size: 18px;
  }
}

@media only screen and (max-width: 387.9px) {
  .hometitle {
    font-size: 25px;
    margin-left: 3.5em;
    margin-top: -3em;
  }
  .IIndheading {
    font-size: 13px;
    margin-left: 7em;
  }
  .home-list {
    margin-left: 5.3em;
    margin-top: -30px;
  }
  .homeimg {
    margin-left: 3.2em;
    width: 18em;
  }
  .home-content {
    font-size: 18px;
  }
}
@media only screen and (max-width: 339.9px) {
  .hometitle {
    font-size: 23px;
    margin-left: 2.8em;
    margin-top: -4em;
  }
  .IIndheading {
    font-size: 13px;
    margin-left: 5em;
  }
  .home-list {
    margin-left: 3.7em;
    margin-top: -30px;
  }
  .homeimg {
    margin-left: 0.9em;
  }
  .home-content {
    font-size: 17px;
  }
}
/* Second Content */
.IInd-hometitle {
  text-align: start;
  color: #001d93;
  font-weight: 600;
  font-size: 45px;
  margin-left: 2em;
}
.IIIndheading {
  text-align: start;
  color: #001d93;
  font-weight: 600;
  font-size: 28px;
}
.hometitleh6 {
  text-align: start;
  margin-left: 4.3em;
  margin-right: 18em;
  font-size: 22px;
  color: #000;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: -1em;
}
.IIndhome-list {
  margin-left: 6em;
  margin-top: -10px;
}

.Requestbutton {
  background-color: #001d93;
  border-radius: 18px;
  color: #fff;
  font-size: 22px;
  width: 6em;
  height: 2em;
  font-weight: bold;
  margin-left: -31em;
  margin-top: -4em;
  padding-top: 0.2em;
  border: none;
  position: relative;
}
.Requestbutton:hover {
  background-color: #1c43e0;
  border: none;
  color: #fff;
}
.Requestbutton:focus {
  outline: 0;
}
.homeimg2 {
  width: 40em;
  margin-left: -14em;
}

@media only screen and (max-width: 1340.9px) {
  .hometitleh6 {
    margin-right: 16em;
  }
}

@media only screen and (max-width: 1300.9px) {
  .hometitleh6 {
    margin-right: 15em;
  }
  .Requestbutton {
    margin-left: -30em;
    margin-top: -3em;
  }
  .homeimg2 {
    width: 38em;
    margin-left: -14em;
  }
}
@media only screen and (max-width: 1149.9px) {
  .homeimg2 {
    width: 36em;
  }
}

@media only screen and (max-width: 1100.9px) {
  .IInd-hometitle {
    margin-left: 1em;
  }
  .hometitleh6 {
    margin-right: 10em;
    margin-left: 2.2em;
  }
  .IIndhome-list {
    margin-left: 3em;
  }

  .Requestbutton {
    margin-left: -23em;
    margin-top: -3.8em;
  }
  .homeimg2 {
    width: 32em;
    margin-left: -12em;
  }
}

@media only screen and (max-width: 947.9px) {
  .IInd-hometitle {
    margin-left: 1em;
    font-size: 30px;
  }
  .IIIndheading {
    font-size: 25px;
  }
  .hometitleh6 {
    margin-right: 6.3em;
    margin-left: 2em;
    font-size: 17px;
    margin-top: -1.5em;
  }
  .IIndhome-list {
    margin-left: 2em;
  }

  .Requestbutton {
    margin-left: -11em;
    margin-top: -4em;
  }
  .homeimg2 {
    width: 23em;
    margin-left: -7em;
  }
}
@media only screen and (max-width: 767.9px) {
  .IInd-hometitle {
    font-size: 25px;
  }
  .IIIndheading {
    font-size: 16px;
  }
  .hometitleh6 {
    margin-right: 1.5em;
    margin-left: 1.5em;
    font-size: 17px;
  }
  .IIndhome-list {
    margin-left: 1em;
  }
  .Requestbutton {
    margin-left: -5em;
    margin-top: -4em;
  }
  .homeimg2 {
    width: 20em;
    margin-left: -2em;
  }
}
@media only screen and (max-width: 575.9px) {
  .IInd-hometitle {
    font-size: 40px;
    margin-left: 3em;
  }
  .IIIndheading {
    font-size: 23px;
    margin-left: 0em;
  }
  .hometitleh6 {
    font-size: 19px;
    margin-left: 6.5em;
    margin-right: 2.8em;
  }
  .IIndhome-list {
    margin-left: 7em;
  }
  .Requestbutton {
    margin-left: 7em;
    margin-top: -22em;
  }
  .homeimg2 {
    width: 28em;
    margin-left: 4em;
  }
}
@media only screen and (max-width: 511.9px) {
  .IInd-hometitle {
    font-size: 35px;
    margin-left: 3.3em;
  }
  .IIIndheading {
    font-size: 20px;
    margin-left: 0em;
  }
  .hometitleh6 {
    font-size: 17px;
    margin-left: 6.8em;
    margin-right: 2.8em;
  }
  .IIndhome-list {
    margin-left: 6.7em;
  }
  .Requestbutton {
    margin-left: 8em;
    margin-top: -22em;
  }
  .homeimg2 {
    width: 28em;
    margin-left: 2em;
  }
}

@media only screen and (max-width: 480.9px) {
  .IInd-hometitle {
    font-size: 33px;
    margin-left: 3.3em;
  }
  .IIIndheading {
    font-size: 20px;
    margin-left: 0em;
  }
  .hometitleh6 {
    font-size: 17px;
    margin-left: 6.8em;
    margin-right: 2.8em;
  }
  .IIndhome-list {
    margin-left: 6.7em;
  }
  .Requestbutton {
    margin-left: 10em;
    margin-top: -19em;
  }
  .homeimg2 {
    width: 22em;
    margin-left: 3em;
  }
}

@media only screen and (max-width: 437.9px) {
  .IInd-hometitle {
    font-size: 28px;
    margin-left: 4em;
  }
  .IIIndheading {
    font-size: 17px;
    margin-left: 0em;
  }
  .hometitleh6 {
    font-size: 17px;
    margin-left: 6.8em;
    margin-right: 2.8em;
  }
  .IIndhome-list {
    margin-left: 6.7em;
  }
  .Requestbutton {
    margin-left: 12em;
    margin-top: -24em;
    font-size: 17px;
    width: 6em;
  }
  .homeimg2 {
    width: 22em;
    margin-left: 2em;
  }
}

@media only screen and (max-width: 394.9px) {
  .IInd-hometitle {
    font-size: 24px;
    margin-left: 3.8em;
  }
  .IIIndheading {
    font-size: 15px;
    margin-left: 0em;
  }
  .hometitleh6 {
    font-size: 17px;
    margin-left: 5.4em;
    margin-right: 10px;
  }
  .IIndhome-list {
    margin-left: 5.2em;
  }
  .Requestbutton {
    margin-left: 12em;
    margin-top: -24em;
    font-size: 17px;
    width: 6em;
  }
  .homeimg2 {
    width: 22em;
    margin-left: 1em;
  }
}

@media only screen and (max-width: 368.9px) {
  .IInd-hometitle {
    font-size: 22px;
    margin-left: 3.7em;
  }
  .IIIndheading {
    font-size: 13px;
    margin-left: 0em;
  }
  .hometitleh6 {
    font-size: 15px;
    margin-left: 5.3em;
    margin-right: 10px;
  }
  .IIndhome-list {
    margin-left: 4.4em;
  }
  .Requestbutton {
    margin-left: 12em;
    margin-top: -22em;
    font-size: 17px;
    width: 6em;
  }
  .homeimg2 {
    width: 20em;
    margin-left: 1em;
  }
}

@media only screen and (max-width: 338.9px) {
  .homeimg2 {
    width: 18em;
    margin-left: 1em;
  }
  .Requestbutton {
    margin-left: 10em;
    margin-top: -21em;
  }
}

/* Ist Moving Card */

.homeimg3 {
  width: 40em;
  margin-top: 2em;
  margin-left: 2em;
}
.carouselfirst {
  height: 29em;
}
.Movingcard1button {
  background-color: #001d93;
  border-radius: 18px;
  color: #fff;
  font-size: 22px;
  width: 6em;
  height: 2em;
  float: right;
  font-weight: bold;
  border: none;
  position: relative;
}
.Movingcard1button:hover {
  background-color: #1c43e0;
  border: none;
  color: #fff;
}
.Movingcard1button:focus {
  outline: 0;
}

@media only screen and (max-width: 1100.9px) {
  .homeimg3 {
    width: 35em;
    margin-top: 5em;
    margin-left: 2em;
  }
}
@media only screen and (max-width: 900.9px) {
  .homeimg3 {
    width: 30em;
    margin-top: 7em;
    margin-left: 0em;
  }
}
@media only screen and (max-width: 767.9px) {
  .homeimg3 {
    width: 35em;
    margin-top: 0em;
    margin-left: 5em;
  }
}
@media only screen and (max-width: 640.9px) {
  .homeimg3 {
    width: 27em;
    margin-top: 0em;
    margin-left: 6em;
  }
}
@media only screen and (max-width: 530.9px) {
  .homeimg3 {
    width: 25em;
    margin-top: 0em;
    margin-left: 4em;
  }
}
@media only screen and (max-width: 464.9px) {
  .homeimg3 {
    width: 20em;
    margin-top: 0em;
    margin-left: 5em;
  }
}

@media only screen and (max-width: 460.9px) {
  .homeimg3 {
    width: 20em;
    margin-top: 0em;
    margin-left: 3em;
  }
}
@media only screen and (max-width: 368.9px) {
  .homeimg3 {
    width: 20em;
    margin-top: 0em;
    margin-left: 1.5em;
  }
}
@media only screen and (max-width: 343.9px) {
  .homeimg3 {
    width: 18em;
    margin-top: 0em;
    margin-left: 1px;
  }
}

/* IInd Moving Card */

.homeimg4 {
  width: 34em;
  margin-top: 2em;
  margin-left: 14em;
}
.bookshomecard {
  text-align: center;
  font-size: 23px;
}
.Movingcard2button {
  background-color: #001d93;
  border-radius: 18px;
  color: #fff;
  font-size: 22px;
  width: 6em;
  height: 2em;
  margin-top: 3.6em;
  float: right;
  margin-right: 3.3em;
  font-weight: bold;
  display: flex;
  justify-content: center;
  padding-top: 0.2em;
  border: none;
  position: relative;
}
.Movingcard2button:hover {
  background-color: #1c43e0;
  border: none;
  color: #fff;
}
.Movingcard2button:focus {
  outline: 0;
}

@media only screen and (max-width: 1370.9px) {
  .homeimg4 {
    margin-left: 7em;
  }
}
@media only screen and (max-width: 1290.9px) {
  .homeimg4 {
    width: 35em;

    margin-left: 8em;
  }
}
@media only screen and (max-width: 1240.9px) {
  .homeimg4 {
    width: 33em;

    margin-left: 120px;
  }
}

@media only screen and (max-width: 1107.9px) {
  .homeimg4 {
    width: 30em;

    margin-left: 80px;
  }
}

@media only screen and (max-width: 958.9px) {
  .homeimg4 {
    margin-left: 40px;
  }
}

@media only screen and (max-width: 890.9px) {
  .homeimg4 {
    width: 26em;

    margin-left: 20px;
  }
}

@media only screen and (max-width: 767.9px) {
  .homeimg4 {
    width: 30em;

    margin-left: 10em;
  }
}

@media only screen and (max-width: 680.9px) {
  .homeimg4 {
    width: 28em;

    margin-left: 8em;
  }
}

@media only screen and (max-width: 580.9px) {
  .homeimg4 {
    width: 24em;

    margin-left: 6em;
  }
}

@media only screen and (max-width: 480.9px) {
  .homeimg4 {
    width: 20em;
    margin-left: 4em;
  }
}

@media only screen and (max-width: 384.9px) {
  .homeimg4 {
    width: 18em;
    margin-left: 2em;
  }
}

@media only screen and (max-width: 320.9px) {
  .homeimg4 {
    width: 17em;
    margin-left: 5px;
  }
}


/* Youtube Iframe */
.youtube-iframe {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsiveyoutube-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 90%;
  height: 90%;
  border-radius: 2%;
}
