/* //Custom Fonts */
@import url("https://fonts.googleapis.com/css?family=Open+Sans&display=swap");

.contact-box {
  position: absolute;
  top: 50%;
  left: 49%;
  width: 500px;
  padding: 40px;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  margin-top: 4em;
}

.contact-box h2 {
  margin: 0 0 30px;
  padding: 0;
  color: #fff;
  text-align: center;
}
.login-hr {
  margin: 0;
}

.contact-box .user-box {
  position: relative;
}
.form-group .form-control:focus {
  box-shadow: none;
}
.contact-box .user-box textarea,
.contact-box .user-box input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
}
.contact-box .user-box label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  pointer-events: none;
  transition: 0.5s;
}

.contact-box .user-box textarea:focus ~ label,
.contact-box .user-box textarea:valid ~ label,
.contact-box .user-box input:focus ~ label,
.contact-box .user-box input:valid ~ label {
  top: -20px;
  left: 0;
  font-family: sans-serif;
  color: #03e9f4;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 1px;
}
.contact-box form a {
  position: relative;
  padding: 5px 5px;
  display: inline-block;
  margin: 0 auto;
  color: #03e9f4;
  font-size: 20px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.5s;
  letter-spacing: 4px;
}

.contact-box form a.submittextcontact {
  margin: 0 auto;
  margin-left: 7.8em;
  cursor: pointer;
}
button.submitbuttoncontact {
  position: relative;
  display: inline-block;
  background: rgba(0, 0, 0, 0);
  border: none;
  color: #03e9f4;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.5s;
  margin-left: 0em;
  display: block;
  margin: 0 auto;
  letter-spacing: 4px;
}
button.submitbuttoncontact:hover {
  background: #03e9f4;
  color: #fff;
  cursor: default !important;
  border-radius: 5px;
  box-shadow: 0 0 5px #03e9f4, 0 0 25px #03e9f4, 0 0 50px #03e9f4,
    0 0 100px #03e9f4;
}

.contact-box a:hover {
  background: #03e9f4;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #03e9f4, 0 0 25px #03e9f4, 0 0 50px #03e9f4,
    0 0 100px #03e9f4;
}

.contact-box a span {
  position: absolute;
  display: block;
}

.contact-box a span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #03e9f4);
  animation: btn-anim1 1s linear infinite;
}

@keyframes btn-anim1 {
  0% {
    left: -100%;
  }
  50%,
  100% {
    left: 100%;
  }
}

.contact-box a span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #03e9f4);
  animation: btn-anim2 1s linear infinite;
  animation-delay: 0.25s;
}

@keyframes btn-anim2 {
  0% {
    top: -100%;
  }
  50%,
  100% {
    top: 100%;
  }
}

.contact-box a span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #03e9f4);
  animation: btn-anim3 1s linear infinite;
  animation-delay: 0.5s;
}

@keyframes btn-anim3 {
  0% {
    right: -100%;
  }
  50%,
  100% {
    right: 100%;
  }
}

.contact-box a span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #03e9f4);
  animation: btn-anim4 1s linear infinite;
  animation-delay: 0.75s;
}

@keyframes btn-anim4 {
  0% {
    bottom: -100%;
  }
  50%,
  100% {
    bottom: 100%;
  }
}

@media (max-width: 931px) {
  .contact-box {
    padding: 14px;
  }
}
@media (max-width: 768px) {
  .contact-box {
    position: relative;
    height: max-content;
    padding-top: 1em;
    margin-left: 3.5px;
  }
  button.submitbuttoncontact {
    margin-bottom: 2em;
  }
  .contact-box form a.submittextcontact {
    margin-left: 7em;
  }
}
