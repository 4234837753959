.cards {
  width: 18rem;
  margin: 0px auto;
  border: none;
  border-radius: 28px;
  height: 19rem;
  background: #fff;
}

a {
  text-decoration: none !important;
  color: black;
}
a:hover {
  text-decoration: none !important;
  color: black;
}
.cards:hover {
  box-shadow: 5px 10px 20px 1px rgba(0, 0, 0.253) !important;
}
.cards-body {
  padding: 1rem 0 !important;
  height: 12rem;
  cursor: default;
}

.card-text {
  font-size: 0.9rem;
  padding: 0.4rem 1.9rem;
  text-align: center;
}
.container-fluid {
  padding-top: 6rem;
  justify-content: center;
  padding-bottom: 6em;
}

.row.gy-5 {
  padding-bottom: 0em;
}
.paddingcourse {
  padding-top: 2em;
  padding-bottom: 2em;
}
.overflow {
  overflow: hidden;
}
.cards-img-top {
  border-radius: 28px;
  height: 9rem;
  width: 100%;
}

.cards-img-top {
  transform: scale(1);
  transition: transform 0.5s ease;
}
.cards-img-top:hover {
  transform: scale(1.8);
}
.cards-title {
  text-align: center;
  font-size: 30px;
}

.col-sm-6 {
  margin-bottom: 5rem;
}

.btn-card {
  margin-left: 7.5rem;
  color: #fff;
  background-color: #000000c9;
  border-radius: 9px;
}

.btn-card:hover {
  background-color: #000 !important;
}

@media only screen and (max-width: 420px) {
  .cards {
    width: auto;
    margin-left: 4rem !important;
    justify-content: center !important;
    margin-right: 3rem;
  }
}

@media only screen and (max-width: 767px) and (min-width: 421px) {
  .cards {
    justify-content: center;
    width: 18em;
    margin: 0px auto;
  }
  .btn-btn-cards {
    margin-left: 6rem !important;
  }
}
@media only screen and (max-width: 1024.9px) {
  .cards {
    width: 16em;
    height: 18em;
    margin: 0px auto !important;
    justify-content: center;
  }
  .cards-title {
    text-align: center;
    font-size: 26px;
  }

  .cards-img-top {
    height: 8rem;
  }
  .btn-card {
    margin-left: 6rem;
  }
}
