/* //Custom Fonts */
@import url("https://fonts.googleapis.com/css?family=Open+Sans&display=swap");


.Notessignup{
font-size:13px;
color: #03e9f4;
margin-top: -1em;
padding-left: 10px;

}


.login-box {
  position: absolute;
  top: 50%;
  left: 49%;
  width: 400px;
  padding: 40px;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  margin-top: 4em;
}

.login-box h2 {
  margin: 0 0 30px;
  padding: 0;
  color: #fff;
  text-align: center;
}
.login-hr {
  margin: 0;
}
.login-box h6{
  color: #fff;
  float: left;
  margin-top: 1em;
  width: 100px;
  white-space: nowrap;
  text-align: center;
  justify-content: center;
  height: 0px;
  padding: 5px 20px;
}


.login-box h6:hover {
  color: #03e9f4;
}
.login-box h7:hover {
  color: #fff;
}

.login-box .user-box {
  position: relative;
}

.login-box .user-box input {
  width: 90%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
}
.login-box .user-box label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  pointer-events: none;
  transition: 0.5s;
}

.login-box .user-box input:focus ~ label,
.login-box .user-box input:valid ~ label {
  top: -20px;
  left: 0;
  font-family: sans-serif;
  color: #03e9f4;
  font-weight: bold;
  font-size: 12px;
  letter-spacing:1px;
}
.login-box form a {
  position: relative;
  padding: 5px 5px;
  display: inline-block;
  margin: 0 auto;
  color: #03e9f4;
  font-size: 20px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.5s;
  letter-spacing: 4px;
  
}
button.accountbutton {
  position: relative;
  display: inline-block;
  background-color: #5a5a6d;
  border: none;
  padding: 0px 0px;
  color: #03e9f4;
  font-size: 16;
  width: 100%;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.5s;
  margin-left: -2em;
  margin-right: 0em;
  letter-spacing: 0px;
}
button.accountbutton:hover {
  background: #03e9f4;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #03e9f4, 0 0 25px #03e9f4, 0 0 50px #03e9f4,
    0 0 100px #03e9f4;
}
.login-box form a.submittext {
  display: table;
}
button.submitbutton {
  position: relative;
  display: inline-block;
  background: rgba(0, 0, 0, 0);
  border: none;
  color: #03e9f4;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.5s;
  margin-left: 0em;
  display: block;
  margin: 0 auto;
  letter-spacing: 4px;
}
button.submitbutton:hover {
  background: #03e9f4;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #03e9f4, 0 0 25px #03e9f4, 0 0 50px #03e9f4,
    0 0 100px #03e9f4;
}

.login-box a:hover {
  background: #03e9f4;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #03e9f4, 0 0 25px #03e9f4, 0 0 50px #03e9f4,
    0 0 100px #03e9f4;
}

.login-box a span {
  position: absolute;
  display: block;
}

.login-box a span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #03e9f4);
  animation: btn-anim1 1s linear infinite;
}

.h4 {
  color: white;
}






input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #fff !important;
    
}





@keyframes btn-anim1 {
  0% {
    left: -100%;
  }
  50%,
  100% {
    left: 100%;
  }
}

.login-box a span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #03e9f4);
  animation: btn-anim2 1s linear infinite;
  animation-delay: 0.25s;
}

@keyframes btn-anim2 {
  0% {
    top: -100%;
  }
  50%,
  100% {
    top: 100%;
  }
}

.login-box a span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #03e9f4);
  animation: btn-anim3 1s linear infinite;
  animation-delay: 0.5s;
}

@keyframes btn-anim3 {
  0% {
    right: -100%;
  }
  50%,
  100% {
    right: 100%;
  }
}

.login-box a span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #03e9f4);
  animation: btn-anim4 1s linear infinite;
  animation-delay: 0.75s;
}


@keyframes btn-anim4 {
  0% {
    bottom: -100%;
  }
  50%,
  100% {
    bottom: 100%;
  }
}

@media (max-width: 931px) {
  .login-box {
    padding: 14px;
  }
  .login-box form{
    margin-left: 1em;
  }

}
@media (max-width: 768px) {
  .login-box {
    position: relative;
    height: max-content;
    padding-top: 1em;
    margin-left: 3.5px;
    margin-top: 0em;
  }
  button.submitbutton{
    margin-bottom: 2em;
  }
}
@media (max-width: 680.9px) {
.login-box h6{
  
  margin-bottom: 2em;
}
}

@media (max-width: 367.9px) {

.login-box form a{
 letter-spacing: 2px;
  
}
}
.google-Signup{
  display: flex!important;
  margin: auto;
}