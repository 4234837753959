.blog {
  height: 100vh;
}
.paddingblog {
  padding: 10px;
}
.blogcard {
  width: 60%;
  margin: 40px auto;
  border: none;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 2px 2px 2px #3f1717;
  height: 60%;
}
.blogcard-text {
  text-align: left;
  font: normal normal normal Bell MT;
  font-size: 15px;
}
.text-muted {
  letter-spacing: 0px;
  font-size: 16px;
  font-weight: bold;
}
.blogtitle {
  font-size: 30px;
  font-weight: bold;
}
.blogtext {
  font-size: 19px;
  font-family: serif;
}
.blog-button {
  background-color: #fdf1f1;
  border-radius: 18px;
  width: 7em;
  height: 2em;
  font-weight: bold;
  border: none;
  justify-content: center;
  align-items: center;
  position: relative;
}
.blog-button:focus {
  outline: 0;
}
.blog-button:hover {
  background-color: #e7cece;
}

.createblog-button {
  background-color: #d8d5ec;
  border-radius: 18px;
  width: 7em;
  height: 2em;
  margin-right: 300px;
  font-weight: bold;
  border: none;
  margin-bottom: 2em;
  float: right;
}
.createblog-button:hover {
  background-color: #c5c1e6;
  border: none;
}
.createblog-button:focus {
  outline: 0;
}

@media (max-width: 1400.9px) {
  .createblog-button {
    margin-right: 270px;
  }
}

@media (max-width: 1120.9px) {
  .createblog-button {
    margin-right: 180px;
  }
  .blogcard {
    width: 70%;
  }
}

@media (max-width: 769.9px) {
  .createblog-button {
    margin-right: 50px;
  }

  .blogcard {
    width: 90%;
  }

  .blogcard-text {
    font-size: 15px;
  }
  .text-muted {
    font-size: 14px;
    font-weight: bold;
    white-space: nowrap;
  }
  .blogtitle {
    font-size: 20px;
  }
  .blogtext {
    font-size: 16px;
  }
}

@media (max-width: 499.9px) {
  .blogtitle {
    font-size: 18px;
  }
}

/* fullblog css */
.blog {
  height: 100%;
}
.fullblogcard {
  width: 90%;
  margin: 0px auto;
  border: none;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 2px 2px 2px #3f1717;
  height: 100%;
}

.fulltext-muted {
  letter-spacing: 0px;
  font-size: 17px;
  color: rgb(126, 124, 124);
  font-weight: bold;
  margin-top: 15px;
  margin-left: 10px;
}
.fullblogtitle {
  font-size: 35px;
  text-align: center;
  font-weight: bold;
  white-space: nowrap;
  overflow: auto;
  width: 100%;
}
.fullblogtext {
  font-size: 19px;
  margin-right: 10px;
  margin-left: 10px;
}
.fullcard-author {
  font-size: 20px;
  color: rgb(126, 124, 124);
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
}

.createfullblog-button {
  background-color: #d8d5ec;
  border-radius: 18px;
  width: 7em;
  height: 2em;
  float: right;
  margin-right: 80px;
  font-weight: bold;
  border: none;
  justify-content: center;
  align-items: center;
  position: relative;
}
.createfullblog-button:hover {
  background-color: #c5c1e6;
  border: none;
}
.createfullblog-button:focus {
  outline: 0;
}

.socialblogicon {
  margin-top: 20px;

  margin-right: 1em;
}

.emailblogicon {
  padding-left: 20px;
  cursor: pointer;
  float: right;
}

.emailblogicon:hover {
  border: none;

  font-size: 20px;
}

@media (max-width: 769.9px) {
  .createfullblog-button {
    margin-right: 50px;
  }
  .fullblogtitle {
    margin-left: 10px;
    font-size: 24px;
    text-align: left;
    margin-right: 0px;
  }
  .fullblogtext {
    font-size: 16px;
    overflow: scroll;
  }
  .fullcard-author {
    font-size: 14px;
    text-align: left;
    margin-left: 13px;
    margin-top: -1em;
  }
}

@media (max-width: 500.9px) {
  .fullblogtitle {
    font-size: 20px;
    width: 90%;
  }

  .fullcard-author {
    font-size: 12px;
  }
}

/* postblog css   */

.postblog {
  border: 1px solid rgba(0, 0, 0, 0.247);
  padding: 1px;

  min-height: 600px;
  background-color: rgba(255, 255, 255, 0.897);
  border-radius: 6px;
}
.postblog-button {
  background-color: #d8d5ec;
  border-radius: 18px;
  width: 10em;
  height: 2em;
  font-weight: bold;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.postblog-button:hover {
  background-color: #c5c1e6;
  border: none;
}
.postblog-button:focus {
  outline: 0;
}

.form-control {
  margin-bottom: 10px;
  border-radius: 6px;
}
