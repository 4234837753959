.Boooksearchdiv {
  margin-bottom: auto;
  margin-top: 0px;
  z-index: 2;
  background-color: #fff;
  border-radius: 6px;
  padding: 5px 0px 0px 0px;
}
.bookssearch {
  color: white;
  border: 0;
  outline: 0;
  background: none;
  width: 0;
  margin-top: 1px;
  caret-color: transparent;
  line-height: 8px;
  transition: width 0.4s linear;
}
.Boooksearchdiv,
.bookssearch {
  margin-left: 7px;
  width: 18em;
  caret-color: #c9c6de;
  font-size: 18px;
  font-family: ui-sans-serif;
  font-weight: 300;
  color: black;
  transition: width 0.4s linear;
}

.usersearch {
  width: 96%;
  margin-left: 7px;
  margin-top: 1em;
  font-family: sans-serif;
  height: 30px;
}
.usersearch:hover {
  width: 100%;
  margin-left: 0em;
  background-color: #b1b1b1c4;
  cursor: pointer;
}
.searchpara {
  margin-left: 2em;
  margin-top: -1em;
}

.Coursesdetail {
  width: 80%;
  height: 100%;
  margin: 0px auto;
  border: none;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 2px 2px 2px #3f1717;
  height: 80%;
}

.Coursestitle {
  margin-left: 1em;
  font-size: 20px;
  margin-right: 1em;
}

.Coursestext {
  margin-left: 1em;
  font-size: 19px;
  margin-right: 1em;
}

.imgCoursesdetail {
  width: 100%;
  height: 200px;
  border-radius: 20px;
  box-shadow: 2px 2px 2px #3f1717;
  opacity: 1;
}

.fullcard-body {
  margin-top: 1em;
  cursor: default;
}

.Learncoursestitle {
  font-size: 34px;
  text-align: center;
  color: #2033b3;
  font-weight: bold;
  margin: 20px;
}
.Coursedetailbutton {
  margin: 40px;
  text-align: center;
  justify-content: center;
}
.Youtubebutton:focus,
.Websitebutton:focus,
.Booksbutton:focus,
.LinkBody:focus {
  outline: 0;
}
.Youtubebutton:hover,
.Websitebutton:hover,
.Booksbutton:hover {
  background-color: #3751ff;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.Youtubebutton,
.Websitebutton,
.Booksbutton {
  margin-right: 1em;
  margin-left: 1em;
  color: #db8b66;
  background-color: #182fc4;
  border-radius: 28px;
  width: 10em;
  font-size: 20px;
  height: 3em;
  font-weight: bold;
  border: none;
  justify-content: center;
  align-items: center;
  position: relative;
}
.table {
  background-color: #dddddd;
  border-color: #707070;
  color: #293893;
  cursor: default;
  text-transform: uppercase;
}
.coursestexts {
  text-transform: uppercase;
  font-weight: bold;
}
.bookname_texts {
  text-align: start;
}
.coursesHeading {
  font-size: 30px;
  font-family: sans-serif;
  font-weight: bold;
  color: #000;
}
.LinkBody {
  background-color: #293893;
  color: #fff;
  width: 60px;
  border-radius: 12px;
  outline: 0;
  border: none;
}
.LinkBody:hover {
  background-color: #000;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

@media (max-width: 1085.9px) {
  .coursesHeading {
    font-size: 25px;
  }
}

@media (max-width: 1005.9px) {
  .Youtubebutton,
  .Websitebutton,
  .Booksbutton {
    width: 8em;
  }

  .coursesHeading {
    font-size: 22px;
  }
}

@media (max-width: 855.9px) {
  .Youtubebutton,
  .Websitebutton,
  .Booksbutton {
    width: 6em;
    height: 2em;
  }
  .Coursesdetail {
    width: 90%;
  }
  .coursesHeading {
    font-size: 19px;
  }

  .coursestexts {
    font-size: 18px;
  }
  .Coursesdetail {
    width: 96%;
  }
}
@media (max-width: 646.9px) {
  .coursesHeading {
    font-size: 17px;
  }

  .coursestexts {
    font-size: 16px;
  }
  .Coursedetailbutton {
    margin: 20px;
  }
}

@media (max-width: 626.9px) {
  .Youtubebutton,
  .Websitebutton,
  .Booksbutton {
    height: 1.8em;
    font-size: 18px;
  }

  .coursesHeading {
    font-size: 16px;
  }

  .coursestexts {
    font-size: 14px;
  }
  .Coursedetailbutton {
    margin: 10px;
  }
  .LinkBody {
    width: 40px;
  }
}

@media (max-width: 543.9px) {
  .Youtubebutton,
  .Websitebutton,
  .Booksbutton {
    font-size: 16px;
  }
}

@media (max-width: 513.9px) {
  .Youtubebutton,
  .Websitebutton,
  .Booksbutton {
    height: 1.8em;
    font-size: 15px;
  }
}

@media (max-width: 494.9px) {
  .Youtubebutton,
  .Websitebutton,
  .Booksbutton {
    font-size: 13px;
    margin-top: 10px;
  }

  .Learncoursestitle {
    font-size: 30px;
  }
  .coursesHeading {
    font-size: 12px;
  }

  .coursestexts {
    font-size: 13px;
  }
}

@media (max-width: 440.9px) {
  .Youtubebutton,
  .Websitebutton,
  .Booksbutton {
    height: 2em;
    font-size: 14px;
    margin-top: 10px;
  }
  .Coursedetailbutton {
    margin: 6px;
    margin-bottom: 30px;
  }

  .imgCoursesdetail {
    height: 150px;
  }

  .Learncoursestitle {
    font-size: 27px;
  }
}

@media (max-width: 373.9px) {
  .Youtubebutton,
  .Websitebutton,
  .Booksbutton {
    height: 2em;
    font-size: 14px;
    margin-top: 10px;
  }

  .Coursedetailbutton {
    margin: 2px;
    margin-bottom: 30px;
  }
  .Learncoursestitle {
    font-size: 25px;
  }
}

@media (max-width: 347.9px) {
  .Youtubebutton,
  .Websitebutton,
  .Booksbutton {
    width: 4.8em;
    height: 2em;
    font-size: 13px;
    margin-top: 10px;
  }

  .Coursedetailbutton {
    margin: 6px;
    margin-bottom: 30px;
  }
  .Learncoursestitle {
    font-size: 23px;
  }
  .Coursedetailbutton {
    margin: 3px;
  }

  .coursesHeading {
    font-size: 11px;
  }

  .coursestexts {
    font-size: 8px;
  }
}

@media (max-width: 310.9px) {
  .Coursedetailbutton {
    margin: 1px;
  }

  .coursesHeading {
    font-size: 9px;
  }

  .coursestexts {
    font-size: 7px;
  }
}
